import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { error_options } from "Common/helpers";
import { SNACK_DURATION } from "Common/helpers";
import { ERROR_MESSAGE } from "Common/helpers";
import { concatAllErrors } from "Common/helpers";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  Table,
  Dropdown,
  Spinner,
  Modal,
  Form,
} from "react-bootstrap";
import { useSnackbar } from "react-simple-snackbar";
import Base from "Views/base";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Quill } from "react-quill";

import { getDate } from "Common/helpers";
import { success_options } from "Common/helpers";

const MedicalDetail = (props) => {
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [show, setShow] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [modalAction, setModalAction] = useState("");
  const englishEditorRef = useRef(null);
  const arabicEditorRef = useRef(null);

  const initialPlaceholderContentEnglish = `
  <p><b><u>Identifying data:</u></b></p><br/><br/>
  <p><b><u>Reason for referral:</u></b></p><br/><br/>
  <p><b><u>Initial impression and diagnosis:</u></b></p><br/><br/>
  <p><b><u>Past psychiatric history:</u></b></p><br/><br/>
  <p><b><u>Medical history:</u></b></p><br/><br/>
  <p><b><u>Treatment plan:</u></b></p><br/><br/>
  <p><b><u>Follow up and update:</u></b></p><br/><br/>
  <p><b><u>Plan and recommendation:</u></b></p><br/><br/>
`;

  const initialPlaceholderContentArabic = `

  <p class="ql-align-right"><b><u>البيانات التعريفية</u></b></p><br/><br/>
  <p class="ql-align-right"><b><u>سبب الإحالة</u></b></p><br/><br/>
  <p class="ql-align-right"><b><u>سبب الإحالة</u></b></p><br/><br/>
  <p class="ql-align-right"><b><u>الانطباع الأولي والتشخيص</u></b></p><br/><br/>
  <p class="ql-align-right"><b><u>التاريخ النفسي السابق</u></b></p><br/><br/>
  <p class="ql-align-right"><b><u>التاريخ النفسي السابق</u></b></p><br/><br/>
  <p class="ql-align-right"><b><u>التاريخ الطبي</u></b></p><br/><br/>
  <p class="ql-align-right"><b><u>خطة العلاج</u></b></p><br/><br/>
  <p class="ql-align-right"><b><u>المتابعة والتحديث</u></b></p><br/><br/>
  <p class="ql-align-right"><b><u>الخطة والتوصية</u></b></p><br/><br/>
  `;

  const [openSnackbarError] = useSnackbar(error_options);
  const medicalId = props.match.params.medicalId;
  const [engValue, setEngValue] = useState(initialPlaceholderContentEnglish);
  const [arabicValue, setArabicValue] = useState(
    initialPlaceholderContentArabic
  );
  const [textChange, setTextChange] = useState(false);

  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;
  const { data: userData } = useQuery(USER_DETAILS);
  var userDetails = userData.userDetails;
  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
  }

  const REQUEST_MEDICAL = gql`
    query getMedical($id: ID!) {
      medicalRequest(id: $id) {
        id
        pk
        created
        status
        statusDisplay
        read
        reasonDisplay
        reason
        otherReason
        languageDisplay
        language
        englishReport
        arabicReport
        englishPdf
        arabicPdf
        patient {
          id
          firstName
          lastName
          identifier
          phone
          email
        }
        doctor {
          id
          firstName
          lastName
        }
        forwardedDoctor {
          id
          firstName
          lastName
        }
        approvedDoctor {
          id
          firstName
          lastName
        }
        assignToApproveDoctor {
          id
          firstName
          lastName
        }
        paymentSources {
          edges {
            node {
              reference
              amountAllocated
              status
              amountRefunded
            }
          }
        }
      }
    }
  `;

  const UPDATE_MEDICAL_REPORT = gql`
    mutation updateMedicalReport(
      $id: Int!
      $englishReport: String
      $arabicReport: String
    ) {
      updateMedicalReport(
        id: $id
        englishReport: $englishReport
        arabicReport: $arabicReport
      ) {
        obj {
          id
        }
      }
    }
  `;

  const APPROVE_MEDICAL_REPORT = gql`
    mutation approveMedicalReport($id: Int!) {
      approveMedicalReport(id: $id) {
        obj {
          id
        }
      }
    }
  `;

  const [updateMedicalReport] = useMutation(UPDATE_MEDICAL_REPORT, {
    onCompleted({ updateMedicalReport }) {
      if (updateMedicalReport) {
        getMedical({ variables: { id: updateMedicalReport?.obj?.id } });
        setTextChange(false);
        openSnackbarSuccess("Updated medical report.", [SNACK_DURATION]);
      }
    },
    onError: (e) => {
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [approveMedicalReport, { loading }] = useMutation(
    APPROVE_MEDICAL_REPORT,
    {
      onCompleted({ approveMedicalReport }) {
        if (approveMedicalReport) {
          getMedical({ variables: { id: approveMedicalReport?.obj?.id } });
          openSnackbarSuccess("Medical report approved.", [SNACK_DURATION]);
        }
      },
      onError: (e) => {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  // get the details of this prescription
  const [getMedical, { data }] = useLazyQuery(REQUEST_MEDICAL, {
    onCompleted({ medicalRequest }) {
      if (medicalRequest?.englishReport) {
        setEngValue(medicalRequest.englishReport);
      }
      if (medicalRequest?.arabicReport) {
        setArabicValue(medicalRequest.arabicReport);
      }
    },
    fetchPolicy: "network-only",
    onError: (e) => {
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    if (medicalId) {
      getMedical({ variables: { id: medicalId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalId]);

  const renderPatientData = useMemo(() => {
    const medicalRequest = data?.medicalRequest;
    const { patient, doctor, created } = medicalRequest || {};

    if (!medicalRequest) {
      return (
        <Row>
          <Col xs={12}>Not found</Col>
        </Row>
      );
    }

    return (
      <>
        <div>
          <p className="mb-0">
            File number: <strong>{patient?.identifier}</strong>
          </p>
          <p className="mb-0">
            Name:{" "}
            <strong>{`${patient?.firstName} ${patient?.lastName}`}</strong>
          </p>
          <p className="mb-0">
            Civil ID: <strong>{patient?.civilId || "-"}</strong>
          </p>
          <p className="mb-0">
            Phone: <strong>{patient?.phone}</strong>
          </p>
        </div>
        <div>
          <p className="mb-0">
            Language: <strong>{medicalRequest?.languageDisplay}</strong>
          </p>
          <p className="mb-0">
            Reason:{" "}
            <strong>
              {medicalRequest?.reason === "OTHERS"
                ? medicalRequest?.otherReason
                : medicalRequest?.reasonDisplay}
            </strong>
          </p>
          <p className="mb-0">
            Status: <strong>{medicalRequest?.statusDisplay}</strong>{" "}
            {medicalRequest?.status === "APPROVED"
              ? `(${medicalRequest?.approvedDoctor?.firstName} ${medicalRequest?.approvedDoctor?.lastName})`
              : ""}
            {medicalRequest?.status === "FORWARDED"
              ? `(${medicalRequest?.forwardedDoctor?.firstName} ${medicalRequest?.forwardedDoctor?.lastName})`
              : ""}
            {medicalRequest?.status === "APPROVAL"
              ? `(${medicalRequest?.assignToApproveDoctor?.firstName} ${medicalRequest?.assignToApproveDoctor?.lastName})`
              : ""}
          </p>
        </div>
        <div>
          <p className="mb-0">
            Requested to :{" "}
            <strong>
              {doctor?.firstName} {doctor?.lastName}
            </strong>
          </p>
          <p className="mb-0">
            Requested date:{" "}
            <strong>{created ? getDate(new Date(created)) : null}</strong>
          </p>
        </div>
      </>
    );
  }, [data?.medicalRequest]);

  const handleInputChange = useCallback((value, setValue, delta, source) => {
    if (source === "user") {
      // Only update state if input is from the user
      setTextChange(true);
    }

    setValue(value);
  }, []);

  // Function to handle undo/redo
  const handleUndoRedo = useCallback((editorRef, action) => {
    if (editorRef.current) {
      const editor = editorRef.current.getEditor(); // Access Quill editor instance
      if (action === "undo") {
        editor.history.undo();
      } else if (action === "redo") {
        editor.history.redo();
      }
    }
  }, []);

  var icons = Quill.import("ui/icons");
  icons["undo"] = "UNDO";
  icons["redo"] = "REDO";

  const renderLanguageTextFields = useMemo(() => {
    const medicalRequest = data?.medicalRequest;

    if (!medicalRequest) return null;

    const renderEditor = (language, value, setValue, ref, isRtl = false) => (
      <div className="my-2">
        <h4>Medical report in {language}</h4>
        <div className="d-flex mt-2 float-right">
          <Button variant="link" onClick={() => handleUndoRedo(ref, "undo")}>
            <i class="fa fa-undo" aria-hidden="true"></i>
          </Button>
          <Button variant="link" onClick={() => handleUndoRedo(ref, "redo")}>
            <i class="fa fa-redo" aria-hidden="true"></i>
          </Button>
        </div>
        <ReactQuill
          ref={ref}
          theme="snow"
          value={value}
          onChange={(text, delta, source) =>
            handleInputChange(text, setValue, delta, source)
          }
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, 4, false] }],
              [{ align: [] }],
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
            ],

            history: {
              delay: 1000, // Delay between actions in ms
              maxStack: 50, // Maximum undo/redo stack size
            },
          }}
          formats={[
            "align",
            "header",
            "bold",
            "italic",
            "underline",
            "list",
            "bullet",
          ]}
        />
      </div>
    );

    switch (medicalRequest?.language) {
      case "ENGLISH":
        return renderEditor("English", engValue, setEngValue, englishEditorRef);
      case "ARABIC":
        return renderEditor(
          "Arabic",
          arabicValue,
          setArabicValue,
          arabicEditorRef,
          true
        );
      default:
        return (
          <>
            {renderEditor("English", engValue, setEngValue, englishEditorRef)}
            {renderEditor(
              "Arabic",
              arabicValue,
              setArabicValue,
              arabicEditorRef,
              true
            )}
          </>
        );
    }
  }, [
    arabicValue,
    data?.medicalRequest,
    engValue,
    handleInputChange,
    handleUndoRedo,
  ]);

  const handleUpdateMedicalRecords = useCallback(() => {
    const payload = {
      id: parseInt(data?.medicalRequest?.pk),
      englishReport: engValue,
      arabicReport: arabicValue,
    };

    if (
      window.confirm(
        "Please note that if the medical record is already APPROVED and you update it. It will return to PENDING. Are you sure you want to continue? "
      )
    ) {
      updateMedicalReport({ variables: payload });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arabicValue, data?.medicalRequest?.pk, engValue]);

  const renderPaymentItems = () => {
    const sources = data?.medicalRequest?.paymentSources?.edges;
    if (sources?.length > 0) {
      return sources.map((item, index) => (
        <tr key={index}>
          <td className="align-middle">
            {item.node.reference ? item.node.reference : "-"}
          </td>
          <td className="align-middle">
            {item.node.amountAllocated ? item.node.amountAllocated : "-"}
          </td>
          <td className="align-middle">{item.node.status}</td>
          <td className="align-middle">
            {item.node.amountRefunded ? item.node.amountRefunded : "-"}
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={3} className="align-middle">
            No payment sources.
          </td>
        </tr>
      );
    }
  };

  const renderApproveButton = useMemo(() => {
    const medicalRequest = data?.medicalRequest;

    const handleApprove = () => {
      const payload = {
        id: parseInt(medicalRequest?.pk),
      };

      if (
        window.confirm("Are you sure you want to APPROVE this medical report?")
      ) {
        approveMedicalReport({ variables: payload });
      }
    };

    if (
      userDetails?.doctor?.canApproveMedicalReport &&
      medicalRequest?.status !== "COMPLETED" &&
      medicalRequest?.status !== "APPROVED"
    ) {
      return <Dropdown.Item onClick={handleApprove}>Approve</Dropdown.Item>;
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.medicalRequest, userDetails?.doctor?.canApproveMedicalReport]);

  const sendAllReport = useCallback(() => {
    const { englishPdf, arabicPdf, pk } = data?.medicalRequest || {};

    if (englishPdf) {
      sendMedicalReport({
        variables: { id: pk, language: "english" },
      });
    }

    if (arabicPdf) {
      sendMedicalReport({
        variables: { id: pk, language: "arabic" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.medicalRequest]);

  const renderPdfActions = useMemo(() => {
    const { englishPdf, arabicPdf, status } = data?.medicalRequest || {};

    if (
      (englishPdf || arabicPdf) &&
      (status === "APPROVED" || status === "COMPLETED")
    ) {
      return (
        <>
          {englishPdf && (
            <Dropdown.Item href={englishPdf} target="_blank">
              Download English report
            </Dropdown.Item>
          )}
          {arabicPdf && (
            <Dropdown.Item href={arabicPdf} target="_blank">
              Download Arabic report
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={sendAllReport}>
            Send report to patient
          </Dropdown.Item>
        </>
      );
    }

    return null;
  }, [data?.medicalRequest, sendAllReport]);

  const REQUEST_DOCTOR = gql`
    query {
      doctors(onlyForwardMedicalReport: true) {
        edges {
          node {
            firstName
            lastName
            id
            email
            identifier
            doctorId
          }
        }
      }
    }
  `;

  const { data: doctors = [] } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onError: (err) => {
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const REQUEST_DOCTOR_APPROVAL = gql`
    query {
      doctors(canApproveMedicalReport: true) {
        edges {
          node {
            firstName
            lastName
            id
            email
            identifier
            doctorId
          }
        }
      }
    }
  `;

  const { data: doctorsApprove = [] } = useQuery(REQUEST_DOCTOR_APPROVAL, {
    fetchPolicy: "network-only",
    onError: (err) => {
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const handleClose = () => setShow(false);

  const handleDoctorChange = useCallback((event) => {
    setSelectedDoctor(event.target.value);
  }, []);

  const DOCTOR_FORWARDING = gql`
    mutation doctorForwarding($id: Int!, $doctorId: Int!, $action: String) {
      doctorForwarding(id: $id, doctorId: $doctorId, action: $action) {
        obj {
          id
          pk
          status
        }
      }
    }
  `;

  const [doctorForwarding, { loading: changeStatusLoader }] = useMutation(
    DOCTOR_FORWARDING,
    {
      onCompleted({ doctorForwarding }) {
        if (doctorForwarding) {
          getMedical({ variables: { id: doctorForwarding?.obj?.id } });
          openSnackbarSuccess("Request successful.", [SNACK_DURATION]);
          handleClose();
          setSelectedDoctor("");
          setModalAction("");
        }
      },
      onError: (e) => {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const SEND_MEDICAL_REPORT = gql`
    mutation sendMedicalReport($id: Int!, $language: String!) {
      sendMedicalReport(id: $id, language: $language) {
        obj {
          id
          pk
          status
        }
      }
    }
  `;

  const [sendMedicalReport, { loading: sendMedicalLoading }] = useMutation(
    SEND_MEDICAL_REPORT,
    {
      onCompleted({ sendMedicalReport }) {
        if (doctorForwarding) {
          openSnackbarSuccess("Report sent.", [SNACK_DURATION]);
        }
      },
      onError: (e) => {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const handleSubmitModal = useCallback(() => {
    const modalTitle = modalAction === "forward" ? "Forwarding" : "Approval";

    const message = `Are you sure you want to send this for ${modalTitle}?`;

    if (window.confirm(message)) {
      const payload = {
        id: data?.medicalRequest?.pk,
        doctorId: selectedDoctor,
        action: modalAction,
      };

      doctorForwarding({ variables: payload });
    }
  }, [data?.medicalRequest?.pk, doctorForwarding, modalAction, selectedDoctor]);

  const renderDoctorSelectionModal = useMemo(() => {
    const modalTitle =
      modalAction === "forward" ? "Forward to doctor" : "Send for approval";

    const doctorList = modalAction === "forward" ? doctors : doctorsApprove;

    return (
      <>
        <Modal size="sm" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label className="w-100">
              Doctors
              <Form.Control
                autoComplete="off"
                as="select"
                name="doctor"
                value={selectedDoctor || ""}
                onChange={handleDoctorChange}
              >
                <option value="">Select Practitioner</option>
                {doctorList &&
                  doctorList?.doctors?.edges?.map((doctor) => {
                    return (
                      <option value={doctor.node.doctorId} key={doctor.node.id}>
                        {doctor.node.firstName} {doctor.node.lastName}
                      </option>
                    );
                  })}
              </Form.Control>
            </Form.Label>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmitModal}
              disabled={changeStatusLoader}
            >
              Select
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }, [
    changeStatusLoader,
    doctors,
    doctorsApprove,
    handleDoctorChange,
    handleSubmitModal,
    modalAction,
    selectedDoctor,
    show,
  ]);

  const handleForward = () => {
    setModalAction("forward");
    setShow(true);
  };

  const handleApproval = () => {
    setModalAction("approval");
    setShow(true);
  };

  return (
    <>
      <Base title={"Medical report details"} showHeader={true}>
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title className="d-flex flex-row justify-content-between ">
                    {renderPatientData}
                  </Card.Title>
                  <Dropdown className="text-right">
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="sm"
                        />
                      ) : (
                        "Actions"
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* only if has approval  */}
                      {renderApproveButton}

                      {data?.medicalRequest?.status !== "APPROVED" &&
                        data?.medicalRequest?.forwardedDoctor === null && (
                          <Dropdown.Item onClick={handleForward}>
                            Forward
                          </Dropdown.Item>
                        )}

                      {!userDetails?.doctor?.canApproveMedicalReport && (
                        <Dropdown.Item onClick={handleApproval}>
                          Request for approval
                        </Dropdown.Item>
                      )}
                      {renderPdfActions}
                    </Dropdown.Menu>
                  </Dropdown>
                  <hr />
                  {renderLanguageTextFields}
                  {textChange && (
                    <>
                      <Button
                        variant="warning"
                        className="mt-3 mr-2"
                        href={`/medical/details/${data?.medicalRequest?.id}`}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        className="mt-3"
                        onClick={handleUpdateMedicalRecords}
                      >
                        Update medical report
                      </Button>
                    </>
                  )}
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Payment details</Card.Title>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Reference</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Amount Refunded</th>
                      </tr>
                    </thead>
                    <tbody>{renderPaymentItems()}</tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Base>
      {renderDoctorSelectionModal}
    </>
  );
};

export default MedicalDetail;
